@import url('https://fonts.googleapis.com/css?family=Bangers|Oswald');

@import "../node_modules/bootstrap/scss/bootstrap";

body {
  .pagecontent {
    font-family: 'Oswald', sans-serif;
    padding: 15px 25px;
    border-radius: 10px;
    background-color: rgba(183, 234, 200, 0.3);
    box-shadow: 5px 5px 5px #696969;
  }

  .buzzwords {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: 25px;
    font-family: 'Bangers', cursive;
    font-size: 80%;
  }

  .buzzword {
    padding-right: 10px;
    color: #405090;
  }

  .buzzword:hover {
    color: #000000;
  }

  .buzzword-small {
    font-size: 75%;
    color: rgba(64, 80, 144, 0.7);
  }
  .buzzword-normal {
    font-size: 100%;
    color: rgba(64, 80, 144, 0.85);
  }
  .buzzword-big {
    font-size: 120%;
  }

  .intro {
    font-size: 100%;
  }

  .title {
    font-size: 180%;
  }

  .lead {
    font-size: 120%
  }

  .footer {
    font-size: 10px;
    color: #cccccc;
  }

  .footer-copy {
    @extend .footer;
    margin-top: 20px;
  }

  .footer-links {
    @extend .footer;
    a {
      color: #337ab7;
    }
    a:hover {
      text-decoration: underline;
    }

  }
}
